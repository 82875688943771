import { createSlice } from '@reduxjs/toolkit';
import { getAllClaims, getClaimDetails, markAsSeen } from '../services/api.service';
import objFromArray from '../utils/objFromArray';

const initialState = {
  activeClaimId: null,
  unReadClaims: [],
  selectedClaim: {},
  selectedTransaction: {},
  claims: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    getClaims(state, action) {
      const { claims } = action.payload;
      state.unReadClaims = claims.filter((claim) => !claim.responsible && (claim.status !== 2));
      state.claims.byId = objFromArray(claims);
      state.claims.allIds = Object.keys(state.claims.byId);
    },
    getClaim(state, action) {
      const { claim } = action.payload;
      state.selectedClaim = claim.claim;
      state.selectedTransaction = claim.transaction;
      state.activeClaimId = claim.claim.claim_id.toString();
    },
    markClaimAsSeen(state, action) {
      const { claimId } = action.payload;
      const claim = state.claims.byId[claimId];

      if (claim) {
        claim.unreadCount = 0;
      }
    },
    resetActiveThread(state) {
      state.activeClaimId = null;
    }
  }
});

export const { reducer } = slice;

export const getClaims = () => async (dispatch) => {
  const response = await getAllClaims();

  dispatch(slice.actions.getClaims({ claims: response.data }));
};

export const getClaim = (threadKey) => async (dispatch) => {
  const response = await getClaimDetails(threadKey);
  dispatch(slice.actions.getClaim({ claim: response.data }));
};

export const markClaimAsSeen = (claimId) => async (dispatch) => {
  await markAsSeen(claimId);

  dispatch(slice.actions.markClaimAsSeen({ claimId }));
};

export const resetActiveThread = () => (dispatch) => {
  dispatch(slice.actions.resetActiveThread());
};

export default slice;
