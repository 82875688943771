import axios from 'axios';

/**
 * Single instance of axios for api communications
 * @return {AxiosInstance} api instance
 */
export const api = axios.create({
  /* eslint no-underscore-dangle: 0 */
  baseURL: `${window._env_.PDV_API_URL}`
});

api.interceptors.request.use((config) => {
  config.params = { ...config.params, 1: 'MDCPgkS5ph5BM' };
  return config;
});

export const apiRecharge = axios.create({
  /* eslint no-underscore-dangle: 0 */
  baseURL: 'http://recharge-senegal.com/apiRechargement'
});

apiRecharge.interceptors.request.use((config) => {
  config.params = { ...config.params, 1: 'MDCPgkS5ph5BM' };
  return config;
});

export const pricesApi = axios.create({
  /* eslint no-underscore-dangle: 0 */
  baseURL: 'https://api.tellus.fr/v1'
});

export const utilitiesApi = axios.create({
  /* eslint no-underscore-dangle: 0 */
  baseURL: 'https://api.tellus.fr/utilities'
});
