import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs, Button, Container, Grid, Link, Typography, Tab,
  Tabs, Divider
} from '@material-ui/core';
import BannerMessageList from '../../components/dashboard/banners/BannerMessageList';
import NumerotationManagement from '../../components/dashboard/numerotation/NumerotationManagement';
import BannersList from '../../components/dashboard/banners/BannersList';
import { getBanners, getNationalStock } from '../../services/api.service';
import StockNationalList from '../../components/dashboard/stock/StockNationalList';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import PlusIcon from '../../icons/Plus';
import gtm from '../../lib/gtm';

const tabs = [
  { label: 'Stock', value: 'stock' },
  { label: 'Banners', value: 'banners' },
  { label: 'Banner Messages', value: 'messages' },
  { label: 'Plan de Numerotation', value: 'numerotation' }
];

const MarketingTools = () => {
  const { settings } = useSettings();
  const [stocks, setStocks] = useState([]);
  const [banners, setBanners] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('stock');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    getNationalStock().then((response) => {
      setStocks(response.data);
    });
    getBanners().then((response) => {
      setBanners(response.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard: Stock Management</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Outils
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Marketing
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Outils
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  onClick={() => setOpen(true)}
                  variant="contained"
                >
                  Import
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box>
            {currentTab === 'stock' && (
              <StockNationalList
                stocks={stocks}
                open={open}
                setOpen={setOpen}
                currentTab={currentTab}
              />
            )}
            {currentTab === 'banners' && (
              <BannersList
                banners={banners}
                setBanners={setBanners}
                open={open}
                setOpen={setOpen}
                currentTab={currentTab}
              />
            )}
            {currentTab === 'messages' && (
              <BannerMessageList />
            )}
            {currentTab === 'numerotation' && (
              <NumerotationManagement />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MarketingTools;
