import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import Modal11 from '../components/widgets/modals/Modal11';

/**
 * Modal context used to pop modals
 * @return {React.Context} modal context
 */
const ModalContext = createContext({
  openModal: () => {},
  openTransactionModal: () => {}
});

/**
 * Modal context value accessor
 * @return {openModal, closeModal} modal context value
 */
export function useModal() {
  return useContext(ModalContext);
}

function ModalProvider({ children }) {
  const [modalContent, setModalContent] = useState(null);
  const [modalData, setModalData] = useState({});
  const [show, setShow] = useState(false);

  /**
   * Open standard modal
   * @param {ModalContent} content
   */
  const openModal = (content) => {
    setModalContent(content);
    setShow(true);
  };

  /**
   * Open standard modal
   * @param {any} content
   */
  const openTransactionModal = (data, content) => {
    if (data) {
      setModalData(data);
    }
    setModalContent(content);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setModalContent(null);
    setModalData({});
  };

  return (
    <ModalContext.Provider value={{ openModal, openTransactionModal }}>
      <Dialog
        open={show}
        onClose={closeModal}
      >
        <Modal11
          show={show}
          content={modalContent}
          data={modalData}
          onClose={closeModal}
        />
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalProvider;
