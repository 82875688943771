/* eslint-disable react/no-unknown-property */
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import LogoTellus from '../../icons/logo tellus.gif';

const sections = [
  {
    title: 'Gestion',
    items: [
      {
        title: 'Tableau de bord'
      },
      {
        title: 'Journaux',
        path: '/dashboard/gestion/journaux'
      },
      {
        title: 'Achats'
      },
      {
        title: 'Factures'
      },
      {
        title: 'Contrôle de gestion'
      },
      {
        title: 'Reporting'
      }
    ]
  },
  {
    title: 'Marketing',
    items: [
      {
        title: 'Galerie images',
        access: 'admin'
      },
      {
        title: 'Promotions',
        path: '/dashboard/marketing/promotions',
        access: 'admin'
      },
      {
        title: 'Informations',
        access: 'admin'
      },
      {
        title: 'Tarifs',
        path: '/dashboard/marketing/products',
        access: 'admin'
      },
      {
        title: 'Outil',
        access: 'admin',
        path: '/dashboard/marketing/stocks'
      }
    ]
  },
  {
    title: 'Service Client',
    items: [
      {
        title: 'Réclamations',
        path: '/dashboard/serviceClient/claims',
        access: 'admin'
      },
      {
        title: 'Monitoring',
        path: '/dashboard/serviceClient/monitoring',
        access: 'admin'
      },
      {
        title: 'Provisionning',
        path: '/dashboard/serviceClient/provisionning',
        access: 'user'
      },
      {
        title: 'Transactions',
        path: '/dashboard/serviceClient/transactions/pdv',
        access: 'user'
      },
      {
        title: 'Outil',
        access: 'admin'
      }
    ]
  },
  {
    title: 'Configuration',
    items: []
  },
  {
    title: 'Sécurité',
    items: []
  },
  {
    title: 'Système',
    items: []
  },
  // existing
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Finance',
        path: '/dashboard/finance',
        icon: <ShoppingBagIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
        access: 'admin'
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Customers',
        path: '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        access: 'admin',
        children: [
          {
            title: 'List',
            path: '/dashboard/customers'
          },
          {
            title: 'Details',
            path: '/dashboard/customers/1'
          },
          {
            title: 'Edit',
            path: '/dashboard/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        path: '/dashboard/products',
        icon: <ShoppingCartIcon fontSize="small" />,
        access: 'admin',
        children: [
          {
            title: 'List',
            path: '/dashboard/products'
          },
          {
            title: 'Create',
            path: '/dashboard/products/new'
          }
        ]
      },
      {
        title: 'Orders',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/orders',
        access: 'admin',
        children: [
          {
            title: 'List',
            path: '/dashboard/orders'
          },
          {
            title: 'Details',
            path: '/dashboard/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        path: '/dashboard/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        access: 'admin',
        children: [
          {
            title: 'List',
            path: '/dashboard/invoices'
          },
          {
            title: 'Details',
            path: '/dashboard/invoices/1'
          }
        ]
      }
    ]
  },
  {
    title: 'Platforms',
    items: [
      {
        title: 'Projects',
        path: '/dashboard/projects',
        icon: <BriefcaseIcon fontSize="small" />,
        access: 'admin',
        children: [
          {
            title: 'Browse',
            path: '/dashboard/projects/browse'
          },
          {
            title: 'Details',
            path: '/dashboard/projects/1'
          },
          {
            title: 'Create',
            path: '/dashboard/projects/new'
          }
        ]
      },
      {
        title: 'Social',
        path: '/dashboard/social',
        icon: <ShareIcon fontSize="small" />,
        access: 'admin',
        children: [
          {
            title: 'Profile',
            path: '/dashboard/social/profile'
          },
          {
            title: 'Feed',
            path: '/dashboard/social/feed'
          }
        ]
      }
    ]
  },
  {
    title: 'Apps',
    items: [
      {
        title: 'Kanban',
        path: '/dashboard/kanban',
        icon: <ClipboardListIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: <ChatAltIcon fontSize="small" />,
        access: 'admin'
      },
      {
        title: 'Calendar',
        path: '/dashboard/calendar',
        icon: <CalendarIcon fontSize="small" />,
        access: 'admin'
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <img
                src={LogoTellus}
                alt="tellus"
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 230
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
